<template>
  <div>
    <!--  This component is used just for redirections only  -->
  </div>
</template>
<script>
import router from "@/router/index.js";
export default {
  data: () => ({}),
  mounted() {
    router.push("patient").catch(() => {});
  },
  computed: {},
};
</script>
